<template>
  <div id="order_list">
    <el-card>
      <AdminTopOper placeholder="请输入订单号">
        <template #defaut>
          <el-select v-model="channel" placeholder="选择订单类型" @change="change">
            <el-option v-for="channelSelect in [{ text: '微信小程序(快递)', id: 3 }]" :key="channelSelect.id" :label="channelSelect.text" :value="channelSelect.id" />
          </el-select>

          <el-select v-model="pageData.where.status" placeholder="选择订单状态" @change="change">
            <el-option v-for="orderStatus in initData.orderStatus" :key="orderStatus.status" :label="orderStatus.name" :value="orderStatus.status" />
          </el-select>

          <el-button @click="click_exportAddress()">导出地址excel</el-button>
          <el-button @click="click_AllorderFile()">批量下载文件</el-button>
         <div>合计：{{ orderAmountAll }}</div>
        </template>
      </AdminTopOper>
      <!-- 表格主体 -->

      <el-table :data="pageData.rows" stripe>
        <el-table-column prop="id" label="编号" width="80" />
        <el-table-column type="expand">
          <template #default="props">
            <OrderExpand :Order="props.row"> </OrderExpand>
          </template>
        </el-table-column>
        <el-table-column prop="number" label="订单号" width="115" />
        <el-table-column prop="name" label="项目名称" width="220" />
        <el-table-column label="文件数量" width="90" v-if="pageData.where.channel == 3">
          <template #default="scope">
            {{ scope.row.Files.length }}
          </template>
        </el-table-column>
        <!-- <el-table-column label="文件大小" width="90" v-if="pageData.where.channel == 3">
          <template #default="scope">
            {{ scope.row.Files.length}}
          </template>
        </el-table-column> -->35
        <el-table-column prop="channel" label="订单渠道" width="175" v-if="pageData.where.channel == 0">
          <template #default="scope">
            <div class="channel">
              <div v-if="scope.row.channel == 3">
                <img class="channel_img" src="@/assets/img/wxMini.png" alt="" />
                <span>微信小程序(快递)</span>
              </div>
              <div v-if="scope.row.channel == 2">
                <img class="channel_img" src="@/assets/img/wxMini.png" alt="" />
                <span>微信小程序</span>
              </div>
              <div v-if="scope.row.channel == 1">
                <img class="channel_img" src="@/assets/img/hand.png" alt="" />
                <span>手工建单</span>
              </div>
              <div v-if="scope.row.channel == 4">
                <img class="channel_img" src="@/assets/img/computer.png" alt="" />
                <span>自助打印(PC)</span>
              </div>
            </div>

            <!-- {{ scope.row.channel }} -->
          </template>
        </el-table-column>
        <el-table-column prop="amount" label="订单金额" width="120">
          <template #default="scope">
            {{ scope.row.amount ? Math.round(scope.row.amount * 100) / 100 : 0 }} 元
            <el-tag type="error" effect="dark" v-if="scope.row.serviceTips">订单异常</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="contact" label="用户信息" width="130">
          <template #default="scope">
            <div @click="click_userId(scope.row.userId)">ID：{{ scope.row.userId }}</div>
            <div>{{ JSON.parse(scope.row.addressJSON).receiver }}</div>
            <el-tag type="error" effect="dark" v-if="scope.row.reAd">有相同地址下单</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="contact" label="联系人" width="130">
          <template #default="scope">
            <div class="orderContactBox">
              <img class="orderContactPortrain" :src="scope.row.portrain" alt="" />
              {{ scope.row.contact ? scope.row.contact : scope.row.username }}
            </div>

            <div v-if="JSON.parse(scope.row.introduction).source">source {{
              scope.row.introduction ? JSON.parse(scope.row.introduction).source : "" }}</div>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="status" label="联系电话" width="100" /> -->
        <el-table-column prop="status" label="订单状态" width="100">
          <template #default="scope">
            <div v-if="scope.row.status == 10">待付款</div>
            <div v-if="scope.row.status == 20 && scope.row.printing != 1">
              <el-tag type="warning" effect="dark">待打印</el-tag>
            </div>
            <div v-if="scope.row.status == 30">
              <el-tag type="success" effect="dark">待收货</el-tag>
            </div>
            <div v-if="scope.row.status == 50">
              <el-tag type="success">订单完成</el-tag>
            </div>
            <div v-if="scope.row.printing == 1 && scope.row.status == 20">
              <el-tag type="warning">开始打印</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="付款方式" width="120">
          <template #default="scope">
            <div style="font-size: 13px">
              {{ orderSource(scope.row) }}
            </div>
            <!-- <span v-if="scope.row.pay_method == 1">
              <el-icon :size="18">
                <Money />
              </el-icon>
              现金
            </span>
            <span v-if="scope.row.pay_method == 2">
              <img class="channel_img" src="@/assets/img/wxpay.png" />
              微信支付Native
            </span>
            <span v-if="scope.row.channel == 3">
              <img class="channel_img" src="@/assets/img/wxpay.png" />
              微信小程序
            </span> -->
          </template>
        </el-table-column>
        <el-table-column prop="createdAt" label="创建时间" width="110" />
        <el-table-column prop="status" label="创建人" width="120" v-if="pageData.where.channel == 0">
          <template #default="scope">
            <div v-if="scope.row.channel != 1">
              <el-icon :size="15">
                <Tools />
              </el-icon>
              系统
            </div>
            <div class="channel">
              <div v-if="scope.row.channel == 1">
                <img class="channel_img avator" src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fitem%2F202002%2F23%2F20200223121423_vfbvv.thumb.1000_0.jpg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1664780357&t=867dfa0b63defe0a1ec349f3c8b1b94a" alt="" />
                管理员
              </div>
            </div>
          </template>
        </el-table-column>
        <AdminTableButton :showEdit="true" width="200" fixed="right">
          <template #default="scope">
            <!-- <el-button @click="handleAdminCard(scope.row.id, scope.row.classifyId)" icon="Rank">详情</el-button> -->
            <el-button @click="click_downloadAllFile2(scope.row)" icon="Rank">下载</el-button>

            <el-button @click="handlePrinting(scope.row)" icon="Printer">打印</el-button>
          </template>
        </AdminTableButton>
      </el-table>

      <AdminPagination :showEdit="false"> </AdminPagination>
    </el-card>

    <el-dialog v-model="pageData.dialogVisible" width="600px" class="adminDialog">
      <el-form ref="form" label-width="100px" size="default">
        <el-form-item label="订单状态:">
          <el-radio-group v-model="pageData.row.status">
            <el-radio-button label="10"></el-radio-button>
            <el-radio-button label="20"></el-radio-button>
            <el-radio-button label="30"></el-radio-button>
            <el-radio-button label="401"></el-radio-button>
            <el-radio-button label="420"></el-radio-button>
            <el-radio-button label="50"></el-radio-button>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="订单价格:">
          <el-input v-model="pageData.row.amount"></el-input>
        </el-form-item>

        <el-form-item label="是否打印:">
          <el-input v-model="pageData.row.printing"></el-input>
        </el-form-item>

        <el-form-item label="物流单号:">
          <el-input v-model="pageData.row.expressNumber"></el-input>
        </el-form-item>

        <el-form-item label="异常信息:">
          <el-input v-model="pageData.row.serviceTips"></el-input>
        </el-form-item>
        <el-form-item label="printTips:">
          <el-input v-model="pageData.row.printTips"></el-input>
        </el-form-item>
      </el-form>

      <template #footer>
        <span class="dialog-footer">

          <el-button @click="pageData.dialogVisible = false">Cancel</el-button>
          <el-button type="primary" @click="pageData.create()">Confirm</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 打印确认对话框 -->
    <el-dialog title="" v-model="isPrinting" width="838px" class="sure_print">
      <span>确定要打印吗？</span>
      <div id="printTest">
        <OrderPrint :order="activeOrder"> </OrderPrint>
      </div>
      <div id="printTest2"></div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="isPrinting = false">取 消</el-button>
          <el-button type="primary" @click="isPrinting = false" v-print="'#printTest'">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup >

import { onMounted, reactive, ref } from 'vue'
import Api from '@/network'
import { request } from '@/network/request'
import { useRouter } from 'vue-router'
import OrderPrint from "./Order-print"
import OrderExpand from "./Order-expand"
import axios from 'axios'
import { ElMessage, ElMessageBox } from 'element-plus'
import store from '../store'
import * as XLSX from "xlsx";
const router = useRouter()
const pageData = reactive(Api.adminPage('order'))
pageData.count = 6
pageData.limit = 6
pageData.where.status = 20
onMounted(pageData.getPage())
const handleWatchCard = (orderName, goodId) => {
  router.push({ name: 'Card', params: { orderName, goodId } })
}
pageData.where.channel = 3
let channel = ref('微信小程序(快递)')
// 打印结算单
let isPrinting = ref(false)
let activeOrder = ref({})

const handlePrinting = async (row) => {
  const Address = row.Address

  let address3 = Address.full + " " + Address.detail + " " + Address.phone + " " + Address.receiver


  let res = await request({
    url: '/fahuo/cainiao_createDo',
    method: 'POST',
    data: {
      address: address3,
      Address: Address,
      orderId: row.id,
      //code2:'73340'
      code2: '73820'

    }
  })
}


let orderAmountAll = ref(0)
setInterval(()=>{
  orderAmountAll.value = 0
  for(let row of pageData.rows){
    orderAmountAll.value = orderAmountAll.value+row.amount
   
  }
},100)
// ====================================
const change = () => {
  pageData.getPage()
}

const click_userId = (userId) => {
  pageData.where.userId = userId
  pageData.getPage()
}

const initData = ref(JSON.parse(localStorage.getItem('initData')))





// ===========================

let json_data = [
  [
    {
      "key": "charset",
      "value": "utf- 8"
    },
    {
      "key": "charset",
      "value": "utf- 8"
    }
  ]
]
const click_exportAddress = () => {


  let order_list = pageData.rows.filter(i => {
    if (!i.serviceTips) return i
  })


  json_data = order_list.map(Order => {
    let addressJSON = JSON.parse(Order.addressJSON)
    let { tips, needPaperCount } = orderTips(Order.Files)
    let note = `
      文件数量 【${Order.Files.length}】| ${Order.userId} 
    【${Order.amount}】|【预计用纸:${needPaperCount}张】
    【${Order.Files[0].pageCount}页】${Order.Files[0].filename}
     `
    //   note = `【${Order.Files[0].pageCount}页】${Order.Files[0].filename}`


    if (Order.Files.length >= 2 && Order.Files[1].filename.length <= 26) {
      note = note + `【${Order.Files[1].pageCount}页】${Order.Files[1].filename}`
    }
    note = note + `\n   提示【${tips}】`
    let obj = {
      "编号（单次最多5000包裹）": addressJSON.phone,
      "收件人姓名": addressJSON.receiver,
      "收件人联系方式": addressJSON.phone,
      "收件地址": addressJSON.full + " " + addressJSON.detail,
      "物品类（在以下6类中选择：日用品、食品、文件、衣物、数码产品、其他）": "文件",
      "物品详情": "",
      "备注信息": note,
    }
    return obj
  })
  console.log(json_data)


  const data = XLSX.utils.json_to_sheet(json_data)//此处tableData.value为表格的数据
  const wb = XLSX.utils.book_new()
  data['!merges']
  data['!rows'] = { "A": "afasfg" }
  // data["!merges"] = [{
  //   b: {//s为开始
  //     c: 1,//开始列
  //     r: 0//可以看成开始行,实际是取值范围
  //   },
  //   e: {//e结束
  //     c: 3,//结束列
  //     r: 0//结束行
  //   }
  // }];

  XLSX.utils.book_append_sheet(wb, data, 'test-data')//test-data为自定义的sheet表名



  XLSX.writeFile(wb, 'test.xlsx')//test.xlsx为自定义的文件名

}


const click_downloadAllFile2 = async (Order) => {

  const Address = Order.Address
  let File_list = Order.Files
  await click_psc_update(File_list)
  let fileSettingTxt = ""
  let indexPage = 0
  setTimeout(async () => {

    let reFiles = []
    let url_list = File_list.map(File => {
      indexPage++
      // File.url = File.url.replace("https://te.ali.yuancong1999.top","http://192.168.1.18:13000")
      let twoDir = `\\【${File.double}】【${File.count}份】【${File.binding}】`

      if (File.binding == "皮纹纸胶装") {
        twoDir = twoDir + `【${File.coverColor}】`
        if (File.coverContent == "文件首页为封面") {
          twoDir = twoDir + `【${File.coverContent}】`
        }
        if (File.coverContent == "文字封面") {
          twoDir = twoDir + `印字：`
          //   twoDir = twoDir + `印字：【${File.coverText}】`
          fileSettingTxt = fileSettingTxt + `印字：【${File.coverText}】 ||`
        }

      }
      // let twoDir = "\\" + File.double + "——" + File.size + "——" + File.count + " 份" + "——" + File.binding
      let deepDir = `【${File.style}】【${File.size}】【${File.material}】` + twoDir
      if (File.scale != "每版打印1页") {
        deepDir = `【${File.scale}】` + deepDir
      }
      // ===================== 文件添加页数显示
      let new_filename = `【${indexPage}】【${File.pageCount}页】` + File.filename
      //   let new_filename = File.filename
      if (File.range != "全部") {
        new_filename = `选页码打印【${File.pageMin}-${File.pageMax} 页】` + new_filename
      }
      fileSettingTxt = fileSettingTxt + "|" + `/${File.style}/${File.size}/${File.material}/${File.style}/${File.size}/${File.material}/` + "|" + new_filename + "|\n\n\n"

      // =====================文件添加页数显示
      new_filename = new_filename.replace('.image', '.jpg')
      let objFile = {
        ...File,
        url: File.url,
        filename: new_filename,
        //  filename: Math.random(),
        deepDir: deepDir,
      }
      for (let refile of reFiles) {
        if (refile.filename == objFile.filename) {
          objFile.filename = `${Math.round(Math.random() * 100)}` + objFile.filename
        }
      }
      reFiles.push(objFile)
      return objFile
    })
    Address.receiver = Address.receiver.replace(" ", "")
    Address.receiver = Address.receiver.replace(" ", "")
    Address.receiver = Address.receiver.replace(" ", "")
    let address2 = Address.full + " " + Address.receiver
    let address3 = Address.full + " " + Address.detail + " " + Address.phone + " " + Address.receiver


    let downloadRes2 = await axios({
      url: 'http://127.0.0.1:12000',
      method: 'POST',
      data: JSON.stringify(url_list),
      params: {
        orderNumber: escape(address2),
        address3: escape(address3 + "\n\n" + fileSettingTxt),
        price: pageData.row.amount,
        //   filesJson: escape(JSON.stringify(url_list)),
        operation: "downloadFiles2"
      }
    })
  }, 1000)

}
const click_psc_update = async (File_list) => {
  let update_dlinkRes = await request({
    url: '/update_dlink',
    method: 'POST',
    data: {
      file_list: File_list
    }
  })
  if (update_dlinkRes.code != -1) {
    console.log(update_dlinkRes.list)
    ElMessage({
      type: 'success',
      message: '下载链接更新成功'
    });

    for (let File of File_list) {
      for (let dfile of update_dlinkRes.list) {
        if (File.fs_id == dfile.fs_id) {
          File.url = dfile.dlink + `&access_token=${store.state.initData.pcs_accessToken}`
        }
      }
    }
    console.log(File_list)
  } else {
    // ElMessage({
    //   type: 'danger',
    //   message: update_dlinkRes.message
    // });
  }
  return 0
}



const orderTips = (File_list) => {

  let paperCount = 0
  let isSingle = false
  let isColor = false
  let isHighColor = false
  let needBinding = File_list.length

  let singleCount = 0
  let jiaozhuang = 0
  let tiequan = 0
  let ding = 0
  let qimading = 0
  let bindingTips = ''
  let is_scale = false
  let is_noAllPage = false

  let is_80g = false

  for (let file of File_list) {
    // 预计纸张 是否单面
    let scale = ''
    scale = file.scale.replace('每版打印', '')
    scale = scale.replace('页', '')
    scale = Number(scale)
    if (scale != 1) { is_scale = true }
    if (file.range != '全部') { is_noAllPage = true }
    let pageCount = file.pageCount / scale

    if (file.double == '双面') {
      if (pageCount % 2 != 0) pageCount = pageCount + 1
      paperCount = paperCount + (pageCount / 2) * file.count
    } else {
      paperCount = paperCount + pageCount * file.count
      isSingle = true
      singleCount++
    }
    if (file.style == '彩色') { isColor = true }
    if (file.style == '激光彩色') { isHighColor = true }

    if (file.binding == '不装订') {
      needBinding = needBinding - file.count
    }
    if (file.binding == '皮纹纸胶装') {
      jiaozhuang = jiaozhuang + file.count
    }
    if (file.binding == '铁圈装') {
      tiequan = tiequan + file.count
    }
    if (file.binding == '钉书针') {
      ding = ding + file.count
    }
    if (file.qimading == '骑马订') {
      qimading = qimading + file.count
    }

    if (file.material == '80g复印纸') {
      is_80g = true
    }

  }

  paperCount = Math.round(paperCount)
  let tips = `${isSingle ? '有单面(' + singleCount + ')，' : ''}${is_80g ? '有80g' : ''}${isColor ? '有彩色，' : ''}${isHighColor ? '有激光彩色，' : ''}${needBinding ? '需要装订:' + needBinding + '本,' : ''}${is_scale ? '有多页合一，' : ''}${is_noAllPage ? '有选页码打印，' : ''}`
  tips = tips + `。${jiaozhuang ? '胶装:' + jiaozhuang + '本,' : ''}${tiequan ? '铁圈装:' + tiequan + '本,' : ''}${qimading ? '骑马订:' + qimading + '本,' : ''}`
  return { tips, needPaperCount: paperCount }
}



const click_AllorderFile = async () => {
  ElMessage({
    type: 'success',
    message: '启动批量下载文件'
  });
  let t = 50 * 1000
  let time = 0
  let rows = pageData.rows.reverse()
  for (let Order of rows) {
    time += t
    setTimeout(() => {
      click_downloadAllFile2(Order)
    }, time)
  }

}

const orderSource = (Order) => {
  if (!Order.referer) Order.referer = ''
  if (Order.referer.indexOf("wx59627e342178c9ed") > 0) {
    return "网上打印"
  }
  if (Order.referer.indexOf("wxdc57537d5b9d474a") > 0) {
    return "学习打印"
  }
  if (Order.referer.indexOf("wx603c78946b0eb8b4") > 0) {
    return "打印店附近"
  }
  if (Order.referer.indexOf("wx1017277d6158ad2f") > 0) {
    return "V打印"
  }
  if (Order.referer.indexOf("wx0fba6f490d770eb9") > 0) {
    return "博立云印"
  }
  if (Order.referer.indexOf("wx1a5ce4b2f651a467") > 0) {
    return "资料打印"
  }
  if (Order.referer.indexOf("wxb3672c56ea5837fc") > 0) {
    return "刺云打印"
  }
  if (Order.referer.indexOf("wx32a13fdb9e70f994") > 0) {
    return "考研云打印"
  }
  if (Order.referer.indexOf("wxa06b26ed966990f7") > 0) {
    return "打印I"
  }


  // return Order.referer
}

const check_re_ad = () => {
  //  重复地址
  let addressIds = []
  for (let order of pageData.rows) {
    let addressId = order.addressId

    for (let ID of addressIds) {
      if (addressId == ID) {
        order.reAd = 1
        console.log('重复')
      }
    }
    addressIds.push(addressId)
  }
}

// ===========================

</script>


<style lang='less' >
#order_list {
  .AdminTableButton .el-button {
    margin-left: 12px;
  }

  .AdminTableButton .el-button:first-child {
    margin-bottom: 8px;
  }

  .AdminTableButton .el-button:nth-child(2) {
    margin-bottom: 8px;
  }
}

.channel_img {
  width: 26px;
  height: 26px;
}

.channel > div {
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    margin-right: 8px;
  }

  span {
    margin-top: 3px;
  }
}

#order_list .el-input--small .el-input__inner {
  text-align: center;
  padding-right: 10px !important;
  //  border: 2px solid rgb(255, 255, 255) !important;
}

.Files {
  max-width: 1100px;
}

.avator {
  border-radius: 50%;
  margin-bottom: 3px;
}

.orderContactBox {
  display: flex;
  flex-direction: row;
  align-items: center;

  .orderContactPortrain {
    width: 30px;
    border-radius: 4px;
    margin-right: 6px;
  }
}
</style>